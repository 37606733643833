import {useState, useEffect, useContext, useRef} from 'react'
import _ from 'lodash'
import {fetch_json} from 'common/networking'
import {parse_resource_id, compose_table_id} from 'common/params_utils'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {omit_multidiff} from 'common/dispatch'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {
  EntityHeaders,
  EntityId,
  Resource,
  ResourceId,
  TableType,
  ZoneDiff,
  ZoneId,
} from 'common/types/storage'
import {useDispatch} from 'react-redux'
import {open_modal} from '../Modals'
import {CommitOptions} from './ConfirmCommitModal'
import {UserAccountContext} from '../UserAccountProvider'
import {ANONYMOUS_USER} from 'common/types/user'
import {ErrorObject, is_error} from 'common/error'
import value_diff from 'common/diffs/base/value_diff'
import conflict_free_value_diff from 'common/diffs/base/conflict_free_value_diff'
import {with_retry} from '../utils/http_utils'
import {_is_invalid_data_table} from '../utils/conflict_utils'
import {load_table} from '../table_data_helpers'

const commit = (zone_id, last_commit_id, multidiff, author) =>
  fetch_json('/commit', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      commit: {
        author,
        committer: author,
      },
      multidiff,
      zone_id,
      last_commit_id,
    }),
  })

const compute_has_conflict_or_invalid = (
  zone_id: ZoneId,
  entity_headers: EntityHeaders,
  resources: Record<ResourceId, Resource>,
  multidiff: ZoneDiff
): boolean => {
  return _.some(resources, (resource, resource_id) => {
    if (resource.status === 'in-progress') {
      return true
    }
    return _is_invalid_data_table(resource_id, resources, multidiff)
  })
}

const get_entity_ids_with_changed_order_by = (multidiff: ZoneDiff): EntityId[] => {
  return Object.keys(
    _.pickBy(
      multidiff,
      // Confirmation dialog is not shown for newly created table unless row ordering is changed.
      // If it is changed confirmation will always be shown, even if final state is no ordering.
      (diff) =>
        (diff.type === 'data_table' || diff.type === 'view_table') &&
        diff.order_by &&
        !_.isEqual(diff.order_by, value_diff().create_diff([])) // default value for new table
    )
  )
}

const get_entity_ids_with_changed_frozen_cols = (multidiff: ZoneDiff): EntityId[] => {
  return Object.keys(
    _.pickBy(
      multidiff,
      (diff) =>
        (diff.type === 'view_table' && diff.frozen_cols) ||
        (diff.type === 'data_table' &&
          diff.frozen_cols &&
          !_.isEqual(diff.frozen_cols, conflict_free_value_diff().create_diff(1)))
    )
  )
}

const commit_options_to_entity_paths = (options: CommitOptions): Record<EntityId, string[]> => {
  return _.merge(
    {
      ...(options.keep_order_by &&
        _(options.keep_order_by)
          .keyBy()
          .mapValues(() => ['order_by'])
          .value()),
    },
    {
      ...(options.keep_frozen_cols &&
        _(options.keep_frozen_cols)
          .keyBy()
          .mapValues(() => ['frozen_cols'])
          .value()),
    }
  )
}

const build_missing_permissions_error_message = (
  error: ErrorObject,
  headers: EntityHeaders
): string => {
  if (error.subtype === 'unauthenticated') {
    return 'You need to be logged in to commit changes.'
  }

  let message = "Saving unsuccessful, you don't have permissions to commit these changes: "
  Object.keys(error.value).forEach((entity_id) => {
    message += `${headers[entity_id].name} (${error.value[entity_id]}), `
  })
  //remove last ', '
  return message.substring(0, message.length - 2)
}

export const useCommit = ({on_error}): [boolean, () => void] => {
  const [saving, set_saving] = useState(false)
  const recommit_options = useRef<CommitOptions>()

  const current_user = useContext(UserAccountContext)
  const dispatch = useDispatch()
  const {dispatch_storage, fetch_commits_action, fetch_entity_headers_action} = useRuntimeActions()
  const {
    runtime: {runtime_context},
    storage,
    resources: {project_resources, table_resources},
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const zone_id = project_resources ? project_resources.project.zone_id : table_resources.zone_id
  const {entity_headers, resources} = storage
  const multidiff = storage.multidiff[zone_id]

  const end_saving = () => {
    set_saving(false)
    recommit_options.current = undefined
  }

  const on_commit = async (options: CommitOptions) => {
    set_saving(true)
    if (compute_has_conflict_or_invalid(zone_id, entity_headers, resources, multidiff)) {
      const error_ids = _.filter(_.keys(resources), (key) =>
        _is_invalid_data_table(key, resources, multidiff)
      ).map((key) => parse_resource_id(key as string).entity_id)
      const ids_message = _.isEmpty(error_ids) ? '' : `Conflicts in tables: ${error_ids.join(', ')}`
      on_error({
        message: `Unable to save changes because of conflicts. You can review all conflicts and resolve them in the Conflicts tab on the right sidebar. ${ids_message}`,
        severity: 'error',
      })
      end_saving()
      return // Do not commit if conflicts are present
    }
    const paths_by_entity_to_keep = commit_options_to_entity_paths(options)
    const to_commit = omit_multidiff(multidiff, paths_by_entity_to_keep)
    if (_.isEmpty(to_commit)) {
      on_error({message: 'No changes were committed', severity: 'warning'})
      end_saving()
      return // nothing to commit
    }

    // at this phase the runtime has been frozen (for rendering)
    // validating some unsaved entities will require recreating the resources
    // fork a new temporary unfrozen runtime to extract the modified TableObjects
    const tmp_runtime = runtime_context.fork({frozen: false}).get_runtime()

    // validate each table from zone and extract the names of the tables containing errors
    const table_types: TableType[] = ['computed_table', 'data_table', 'view_table']
    const tables_with_errors = _.keys(multidiff)
      .filter((key) => multidiff[key]?.type in table_types)
      .map((table_id) => {
        const full_table_id = compose_table_id({entity_id: table_id})
        return load_table(tmp_runtime, full_table_id)
      })
      .filter((full_table) => full_table._contains_error_data())
      .map((full_table) => full_table.name)

    if (tables_with_errors.length) {
      on_error({
        message: `Cannot commit tables with errors: ${tables_with_errors.join(', ')}`,
        severity: 'error',
      })
      end_saving()
      return // Do not commit if error is present in zone
    }

    const author = current_user?.email || ANONYMOUS_USER
    const response = await with_retry(commit)(zone_id, storage.versions[zone_id], to_commit, author)

    if (is_error(response) && response.type === 'missing-privileges') {
      on_error({
        message: build_missing_permissions_error_message(response, storage.entity_headers),
        severity: 'error',
      })
      end_saving()
      return
    }
    if (is_error(response) && response.subtype === 'archived-entity') {
      on_error({
        message: response.message,
        severity: 'error',
      })
      end_saving()
      return
    }

    await fetch_entity_headers_action()
    await fetch_commits_action(zone_id)

    if (!response.resync) {
      dispatch_storage({type: 'commit', zone_id, keep_diff_paths: paths_by_entity_to_keep})
    } else {
      switch (response.resync.reason) {
        case 'new-commits':
          on_error({message: 'New commits retrieved', severity: 'info'})
          recommit_options.current = options
          // early return, keep saving set to true;
          // on_commit might be retried after the storage is updated
          return
        case 'entity-conflicts-or-invalids':
          on_error({
            message:
              'New commits retrieved, fix conflicts, and save again. You can review all conflicts and resolve them in the Conflicts tab on the right sidebar.',
            severity: 'error',
          })
          break
        default:
          on_error({message: 'Saving unsuccessful, please try again', severity: 'error'})
          break
      }
    }
    end_saving()
  }

  const try_on_commit: typeof on_commit = async (options) => {
    try {
      await on_commit(options)
    } catch (e) {
      console.error(e)
      on_error({
        message: 'Something went wrong, your changes may be unsaved. Please try again later.',
        severity: 'error',
      })
      set_saving(false)
    }
  }

  const on_precommit = async () => {
    const entity_ids_with_order_by = get_entity_ids_with_changed_order_by(multidiff)
    const entity_ids_with_frozen_cols = get_entity_ids_with_changed_frozen_cols(multidiff)
    if (entity_ids_with_order_by.length > 0 || entity_ids_with_frozen_cols.length > 0) {
      dispatch(
        open_modal('confirm_commit', {
          on_submit: try_on_commit,
          entity_ids_with_order_by,
          entity_ids_with_frozen_cols,
        })
      )
    } else {
      await try_on_commit({})
    }
  }

  const start_saving = () => {
    if (saving) return
    on_precommit()
  }

  const storage_current_version = storage.versions[zone_id]
  useEffect(() => {
    if (recommit_options.current) {
      try_on_commit(recommit_options.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage_current_version])

  return [saving, start_saving]
}
